<template>
  <div
    @click="openForm"
    class="card mx-auto text-inactive rounded-lg card-container add-card-container"
  >
    <div class="top-right mt-0 row" style="right: 0px">
      <div class="col-4 p-0">
        <small
          style="font-size: 10px"
          class="color-inactive"
        >off/on</small>
      </div>
      <div class="col-7 p-0">
        <div class="field">
          <div class="ui toggle checkbox" style="margin-top: 2px">
            <label></label>
            <input
              type="checkbox"
              disabled
              v-model="active"
              tabindex="0"
              class="hidden"
              @change="onStatusChanged"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1">
      <div align-self="center" class="text-center col-3 p-0 pl-4 ml-2">
        <img
          style="max-height: 65px"
          src="@/assets/img/icons/building_inactive.png" />
      </div>
      <div class="col-6 pt-2 text-center">
        <i
          class="plus circle icon color-inactive"
          style="font-size: 50px"
        ></i>
      </div>
    </div>
  </div>

  <BuildingFormModal
    :show="showModalForm"
    @close="closeForm"
  />
</template>

<script>
import BuildingFormModal from '../modals/BuildingFormModal.vue'

export default {
  name: 'BuildingAddCard',
  components: { BuildingFormModal },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    showModalForm () {
      return this.showModal
    }
  },
  methods: {
    openForm () {
      this.showModal = true
    },

    closeForm () {
      this.showModal = false
    }
  }
}
</script>

<style scoped>
  .add-card-container {
    cursor: pointer;
  }

  .add-card-container:hover {
    opacity: 0.7;
  }
</style>
