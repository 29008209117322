<template>
  <SuiModal basic v-model="dialog">
    <SuiModalContent >
      <div class="modal-container">
        <a @click="close"  class="close-icon">
            <i class="times icon general-blue-color"></i>
          </a>
          <SuiModalDescription>
            <div class="align-items-center div-form ">
              <h2
                class="general-blue-color"
                style="margin-top: -16px; margin-bottom: 30px; font-size: 17px"
              >{{$t('building.create')}}</h2>

              <div class="px-3">
                <div class="row mt-3">
                  <div class="col-md-2 pt-2">
                    <label>{{$t('building.name')}}</label>
                  </div>
                  <div class="col-md-10 ui input">
                    <input type="text" v-model="name" placeholder="">
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-2 pt-2">
                    <label>{{$t('building.location')}}</label>
                  </div>
                  <div class="col-md-10 ui input">
                    <!-- <input type="text" id="address-map" v-model="address" placeholder=""> -->
                    <VueGoogleAutocomplete
                      id="address-google"
                      classname="form-control"
                      :placeholder="$t('building.enterLocation')"
                      type="(address)"
                      ref="address"
                      :country="['co', 've', 'mx', 'cw','es']"
                      @keypress="isAddressValid = false"
                      @placechanged="getAddressData"
                    >
                      <!-- :fields="gMapsFields" -->
                    </VueGoogleAutocomplete>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-2"></div>
                  <div class="col-md-10 prim-color">
                    <i class="exclamation circle icon"></i> {{$t('building.enterLocation')}}
                  </div>
                </div>

                <div class="w-100 mt-4 text-center">
                  <Button :text="$t('buttons.create')" @clicked="save" color="primary" />
                </div>
              </div>
            </div>
          </SuiModalDescription>
      </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Button from '../form/Button.vue'

export default {
  name: 'BuildingFormModal',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    VueGoogleAutocomplete,
    Button
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isAddressValid: false,
      name: '',
      address: '',
      coordinates: { latitude: 0, longitude: 0 },
      gMapsFields: [
        // 'address_components',
        // 'adr_address',
        // 'alt_id',
        // 'formatted_address',
        // 'geometry',
        // 'icon',
        // 'id',
        // 'name',
        // 'photo',
        // 'place_id',
        // 'scope',
        // 'type',
        // 'url',
        'utc_offset_minutes'
        // 'vicinity'
      ]
    }
  },
  computed: {
    dialog () {
      return this.show
    }
  },
  methods: {

    getAddressData (addressData) {
      // console.log('addressData ---->', addressData)
      const { latitude, longitude } = addressData
      this.address = document.getElementById('address-google').value
      // console.log('this.address ----> ', this.address)
      // console.log('utc_offset_minutes ----> ', document.getElementById('address-google').utc_offset_minutes)
      this.coordinates.latitude = latitude
      this.coordinates.longitude = longitude
      this.$refs.address.blur()
      this.isAddressValid = true
    },

    save () {
      if (this.name === '' || this.address === '') {
        return this.$swal(this.$t('building.fillAllFields'), '', 'warning')
      }

      if (!this.isAddressValid) {
        return this.$swal(this.$t('building.invalidLocation'), this.$t('building.validLocation'), 'warning')
      }

      const addressArray = this.address.split(', ')
      const address = addressArray[0]
      const city = addressArray[1]
      const state = (addressArray.length === 3) ? city : addressArray[2]
      const country = (addressArray.length === 3) ? addressArray[2] : addressArray[3]

      this.$store.dispatch('residential/addResidential', {
        name: this.name,
        address,
        city,
        state,
        country,
        latitude: this.coordinates.latitude.toString(),
        longitude: this.coordinates.longitude.toString()
      }).then(() => {
        this.$swal(this.$t('building.success'), '', 'success')
        this.close()
      }).catch((error) => {
        console.log(error)
        this.$swal(this.$t('building.error'), this.$t('building.errorMessage'), 'error')
      })
    },

    close () {
      this.clearForm()
      this.$emit('close')
    },

    clearForm () {
      this.name = ''
      this.address = ''
      if (this.$refs.address) {
        this.$refs.address.clear()
      }
    }
  }
}
</script>

<style scoped>
  .modal-container {
    background-color: #fff;
    color: black;
    width: 655px;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
  }
</style>
