<template>
  <div>
    <div class="home-container">
      <!-- NO MASTER -->
      <div>
        <div class="row">
          <div class="row">
            <div class="col-lg-1 col-sm-1 col-3 py-3" style="text-align: left">
              <span class="text-muted">{{ $t('status.active') }}</span>
            </div>
            <div class="col-lg-11 col-sm-10 col-9 pt-2"><hr class="text-muted" /></div>
          </div>
          <div class="row" v-if="buildings.noMasters.active.length">
            <div
              v-for="item in buildings.noMasters.active"
              :key="item.name"
              class="col-lg-4 col-sm-6"
            >
              <BuildingCard
                :data="item"
                :statusChanged="statusChanged"
              />
            </div>
            <div class="col-lg-4 col-sm-6">
              <BuildingAddCard />
            </div>
          </div>
          <div v-else class="row">
            <div class="col-lg-4 col-sm-6">
              <BuildingAddCard />
            </div>
          </div>
        </div>

        <div
          v-if="buildings.noMasters.inactive.length"
          class="mt-2">
          <div class="row">
            <div class="col-lg-1 col-sm-2 col-3 py-3" style="text-align: left">
              <span class="text-muted">{{ $t('status.inactive') }}</span>
            </div>
            <div class="col-lg-11 col-sm-10 col-9 pt-2"><hr class="text-muted" /></div>
          </div>
          <div class="row">
            <div
              v-for="item in buildings.noMasters.inactive"
              :key="item.title"
              class="col-lg-4 col-sm-6"
            >
              <BuildingCard
                :data="item"
                :statusChanged="statusChanged"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- MASTER -->
      <div v-for="master in buildings.masters" :key="master.residentialId" class="mt-5">
        <div class="row">
        <div class="col-lg-1 col-sm-1 col-3 py-3" style="text-align: left">
          <span class="text-muted">Master</span>
        </div>
        <div class="col-lg-11 col-sm-10 col-9 pt-2"><hr class="text-muted" /></div>
      </div>
      <div class="row">
          <div class="col-lg-4 col-sm-6">
            <BuildingCard
              :data="master"
              :isMaster="true"
              :statusChanged="statusChanged"
            />
          </div>
        </div>

        <div class="row">
          <div class="row">
            <div class="col-lg-1 col-sm-1 col-3 py-3" style="text-align: left">
              <span class="text-muted">{{ $t('status.active') }}</span>
            </div>
          </div>
          <div class="row">
            <div
              v-for="item in master.children.active"
              :key="item.name"
              class="col-lg-4 col-sm-6"
            >
              <BuildingCard
                :data="item"
                :statusChanged="statusChanged"
              />
            </div>
            <div class="col-lg-4 col-sm-6">
              <BuildingAddCard />
            </div>
          </div>
        </div>

        <div
          v-if="master.children.inactive.length > 0"
          class="mt-2">
          <div class="row">
            <div class="col-lg-1 col-sm-2 col-3 py-3" style="text-align: left">
              <span class="text-muted">{{ $t('status.inactive') }}</span>
            </div>
          </div>
          <div class="row">
            <div
              v-for="item in master.children.inactive"
              :key="item.title"
              class="col-lg-4 col-sm-6"
            >
              <BuildingCard
                :data="item"
                :statusChanged="statusChanged"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-container">
        <FeedCarousel class="carousel" />
    </div>
  </div>
</template>

<script>
import BuildingCard from '@/components/ui/BuildingCard'
import BuildingAddCard from '@/components/ui/BuildingAddCard'
import FeedCarousel from '@/components/ui/FeedCarousel'

export default {
  components: {
    BuildingCard,
    BuildingAddCard,
    FeedCarousel
  },
  computed: {
    buildings () {
      const allBuildings = this.$store.getters['residential/residentials']
      const buildings = allBuildings.filter(building => building.isMaster === true)
      const masterBuildings = buildings.map(building => ({
        ...building,
        children: {
          active: allBuildings.filter(
            child => child.masterId === building.residentialId && child.status === this.status.ACTIVE
          ),
          inactive: allBuildings.filter(
            child => child.masterId === building.residentialId && child.status === this.status.INACTIVE
          )
        }
      }))

      const noMaster = allBuildings.filter(b => b.isMaster === false && b.masterId === 0)

      return {
        masters: masterBuildings,
        noMasters: {
          active: noMaster.filter(b => b.status === this.status.ACTIVE),
          inactive: noMaster.filter(b => b.status === this.status.INACTIVE)
        }
      }
    },
    buildingsActive () {
      return this.buildings.filter(x => x.status === this.status.ACTIVE)
    },
    buildingsInactive () {
      return this.buildings.filter(x => x.status === this.status.INACTIVE)
    }
  },
  created () {
    this.$store.dispatch('residential/setActiveResidential', {})
    this.$store.dispatch('reservation/setReservations', [])
  },
  methods: {
    statusChanged (id, active, masterId) {
      if (masterId > 0) {
        const children = this.buildings.masters.find(b => b.residentialId === masterId).children
        let child
        if (active) {
          child = children.inactive.find(c => c.id === id)
        } else {
          child = children.active.find(c => c.id === id)
        }
        child.status = (active === true) ? this.status.ACTIVE : this.status.INACTIVE
      } else {
        let building
        if (active) {
          building = this.buildings.noMasters.inactive.find(x => x.id === id)
        } else {
          building = this.buildings.noMasters.active.find(x => x.id === id)
        }
        building.status = (active === true) ? this.status.ACTIVE : this.status.INACTIVE
      }
    }
  }
}
</script>
