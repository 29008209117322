<template>
  <div
    class="card mx-auto rounded-lg card-container"
    :class="{ 'color-inactive': (active === false) }"
  >
    <div v-if="!isMaster" class="top-right mt-0 row">
      <div class="col-4 p-0">
        <small
          style="font-size: 10px"
          class="text-muted"
        >off/on</small>
      </div>
      <div class="col-8 ui form" style="padding-top: 2px">
        <div class="field">
          <SuiCheckbox
            toggle
            v-model="isActive"
            @change="onStatusChanged"
          />
        </div>
      </div>
    </div>
    <div v-else class="top-right mt-0 row">
      <small
        style="font-size: 10px; font-weight: bold;"
        class="text-muted"
      >Master</small>
    </div>

    <small
      class="bottom-right text-created m-2 mt-1"
      :class="{ 'text-muted': active }"
      style="margin-bottom: 0.6rem"
    >{{ $t('label.created') }}: {{ createdAt }}</small>

    <div class="row my-1">
      <div align-self="center" class="text-center col-3 p-0 pl-4 ml-1">
        <img
          v-if="active"
          @click="manageResidential(data)"
          src="@/assets/img/icons/building_active.png" />
        <img
          v-else
          src="@/assets/img/icons/building_inactive.png" />
      </div>
      <div class="col m-0 p-0">
        <div class="row">
          <div class="col-12 text-left">
            <p class="mb-0" style="font-size: 17px">{{ data.name }}</p>
            <span style="font-size: 13px">{{ data.address }}, {{data.city}}</span>
            <div class="row" style="margin-top: 10px">
              <div class="col" v-if="active">
                <Button
                  v-if="!isMaster"
                  :text="$t('building.administrar')"
                  class="d-inline"
                  color="secondary"
                  small
                  @clicked="manageResidential(data)" />
                <Button
                  color="primary"
                  :text="$t('building.edit')"
                  class="d-inline ml-1"
                  small
                  @clicked="editResidential(data)" />
                <!-- <Button
                  v-if="!isMaster"
                  color="third-color"
                   :text="$t('building.delete')"
                  class="d-inline ml-1"
                  small
                  @clicked="deleteResidential(data)" /> -->
              </div>
              <div class="col" v-else>
                <InactiveButton :small="true" class="d-inline" />
                <InactiveButton :small="true" class="d-inline ib-ml-1" />
                <InactiveButton :small="true" class="d-inline ib-ml-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { SuiCheckbox } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'
import InactiveButton from '@/components/form/InactiveButton'
import { updateStatusResidential } from '@/api/residential'
import helper from '../../utils/helpers'

export default {
  name: 'BuildingCard',
  components: {
    SuiCheckbox,
    Button,
    InactiveButton
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isMaster: {
      type: Boolean,
      required: false,
      default: false
    },
    statusChanged: { type: Function }
  },
  date () {
    return {
      active: false
    }
  },
  created () {
    this.active = this.data.status === this.status.ACTIVE
  },
  computed: {
    createdAt () {
      return helper.formatDateDDMMYYServer(this.data.createdAt)
    },
    isActive () {
      return this.active
    }
  },
  methods: {
    manageResidential (residential) {
      this.$store.dispatch('residential/setActiveResidential', residential)
      if (residential.isMaster) {
        this.$router.push({ path: '/residential/edition' })
      } else {
        this.$router.push({ path: '/residential' })
      }
    },
    editResidential (residential) {
      this.$store.dispatch('residential/setActiveResidential', residential)
      this.$router.push({ path: '/residential/edition' })
    },
    deleteResidential (residential) {
      this.$swal({
        title: `¿Realmente quieres eliminar ${residential.name}?`,
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('residential/removeResidential', residential.residentialId).then(() => {
            this.$swal(`${residential.name} ha sido eliminado`, '', 'success')
          }).catch(() => {
            this.$swal(`Ha ocurrido un error interno y no se pudo eliminar ${residential.name}`, '', 'error')
          })
        }
      })
    },
    onStatusChanged () {
      const isAuthenticated = this.$store.getters['oauth/isAuthenticated']
      if (!isAuthenticated) {
        alert('Te invitamos a que creas una cuenta.')
        return
      }
      this.active = !this.active
      updateStatusResidential(this.data.id, this.active)
        .then(() => {
          this.statusChanged(this.data.id, this.active, this.data.masterId)
        })
        .catch((err) => console.log(err))
    }
  }
}
</script>

<style scoped>
  .ib-ml-1 {
    margin-left: 3px
  }

  .bottom-right {
    margin-bottom: 0.6rem !important;
  }

  @media screen and (min-width: 1100px) {
    .bottom-right {
      margin-bottom: 0.9rem !important;
    }
  }
</style>
