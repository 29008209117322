<template>
  <div>
    <button
      class="inactive-button background-inactive text-center"
      :class="{'small-button': small }"
    >
      <span :class="{'text-small': small }"
      >{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'InactiveButton',
  props: {
    text: {
      required: false,
      type: String
    },
    small: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .inactive-button {
    padding: 0px 25px;
    height: 28px;
    text-transform: none !important;
    letter-spacing: normal;
    border: 0;
    border-radius: .28571429rem;
  }
  .small-button {
    max-height: 15px !important;
    min-width: 44px !important;
    padding: 0px 5px !important;
  }
  .text-small {
    font-size: 9px;
  }
</style>
