<template>
  <Carousel :autoplay="7000">
    <Slide v-for="item in itemsCarousel" :key="item.id">
      <div class="row fill-height w-100">
        <div class="col-lg-6 col-sm-6 col-md-6 text-left" style="padding-left: 15px">
          <h3 class="mb-2">{{ item.title }}</h3>
          <p class="text-muted carousel-description">
            {{ item.description }}
          </p>
          <!-- <a :href="item.actionUrl" target="_blank" rel="noreferrer noopener"> -->
            <Button
              class="mb-2"
              :text="item.actionLabel"
              color="primary"
              @clicked="openUrl(item.actionUrl)"
            ></Button>
          <!-- </a> -->
        </div>
        <!-- <div class="col-lg-6 col-md-5 col-sm-5">
          <img class="imgSlider" :src="item.image" />
        </div> -->
      </div>
    </Slide>

    <!-- <template #addons>
      <Pagination />
    </template> -->
  </Carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { defineComponent } from 'vue'
import { getFeed } from '@/api/feed'
// Pagination
import { Carousel, Slide } from 'vue3-carousel'
import Button from '@/components/form/Button'

export default defineComponent({
  name: 'FeedCarousel',
  components: {
    Button,
    Carousel,
    Slide
    // Pagination
  },
  data () {
    return {
      itemsCarousel: []
    }
  },
  created () {
    this.getFeed()
  },
  methods: {
    getFeed () {
      getFeed()
        .then(({ data }) => (this.itemsCarousel = data))
        .catch((err) => console.log(err))
    },

    openUrl (url) {
      window.open(url, '_blank')
    }
  }
})
</script>

<style scoped>
  a:hover {
    text-decoration: none;
  }
  .carousel-container {
    height: auto;
  }
  .imgContainer {
    border-radius: 15px;
    height: 190px;
    width: 100%;
    background-color: red;
  }
  .carousel-title {
    color: #717177;
    font-weight: bold;
  }
  .carousel-description {
    color: #a5a5a5;
    font-size: 14px;
  }
  .imgSlider {
    max-width: 100%;
    max-height: 190px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
  }
</style>
